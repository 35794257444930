{
  "daytime": {
    "earlier": "inizio giornata",
    "later": "nel corso della giornata"
  },
  "login": {
    "title": "Login",
    "text": "Accedi al tuo account",
    "button": "Login",
    "stressLevel": "Livello di stress",
    "errorDialog": {
      "message": "Errore nome utente o password!",
      "accept": "Ok"
    },
    "placeholder": {
      "username": "Inserire nome utente",
      "password": "Inserire password"
    }
  },
  "sidebar": {
    "bulletins": "Valanghe.report",
    "dangerSources": "Origini di pericolo",
    "observations": "Osservazioni",
    "qfa": "QFA",
    "modelling": "Modelli",
    "modellingForecast": "Previsione",
    "modellingZamg": "Multi modelli dello ZAMG ",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "GeoSphere meteogramma",
    "modellingSnowpack": "Modello SNOWPACK",
    "modellingSnowpackMeteo": "Dati meteo simulati SNOWPACK",
    "weatherbox": "Dashboard meteo",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Logout",
    "language": "Lingue",
    "en": "Inglese",
    "de": "Tedesco",
    "fr": "Francese",
    "it": "Italiano",
    "es": "Spagnolo",
    "ca": "Catalano",
    "oc": "Aranese",
    "fullscreen": "A schermo pieno",
    "settings": "Impostazioni",
    "statistics": "Statistiche",
    "admin": "Admin",
    "region": "Meteo-nivo zona",
    "education": "Formazione"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Stabilità del manto nevoso",
      "frequency": "Frequenza",
      "avalancheSize": "Dimensione valanga",
      "dangerRating": "Grado del pericolo",
      "override": "(modifica valutazione grado del pericolo)",
      "matrix": "Matrice",
      "referToFairStability": "Fare riferimento alla classe \"Discreta\"",
      "referToPoorStability": "Fare riferimento alla classe \"Scarsa\"",
      "dangerLevel1": "Grado di pericolo 1 (debole)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "manca",
      "available": "disponibile",
      "forecast": "previsione",
      "analysis": "analisi",
      "loading": "Caricamento origini di pericolo ...",
      "saveError": "L'origine di pericolo non poteva essere salvata!",
      "loadDangerSourcesError": "L'origine di pericolo non può essere caricato!",
      "loadVariantsError": "Le variazioni dell'origine di pericolo non può essere caricato!"
    },
    "variantStatus": {
      "active": "attivo",
      "dormant": "muto",
      "inactive": "inattivo"
    },
    "tooltip": {
      "read": "Leggi origine di pericolo",
      "edit": "Modifica l'origine di pericolo"
    },
    "table": {
      "loadMore": "Carica {{count}} più giorni",
      "title": {
        "forecast": "Previsione",
        "analysis": "Analisi"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "Questa variazione può essere modificata.",
      "variantNotEditable": "Questa variazione non può essere modificata.",
      "noVariantsForSelectedDay": "Non sono state definite variazioni per questa data.",
      "tooltip": {
        "editDangerSource": "Modifica l'origine di pericolo",
        "loadVariants": "Carica le variazioni del giorno precedente",
        "edit": "Modifica le micro-zone",
        "copy": "Copia variazione",
        "delete": "elimina la variazione",
        "compare": "paragona le variazioni",
        "forecast": "paragona con la previsione"
      },
      "createDangerSource": "crea un'origine di pericolo",
      "createVariant": "crea una nuova variazione",
      "noDangerSources": "nessuna origine di pericolo",
      "title": {
        "avalanche": "valanga",
        "matrix": "Matrice",
        "characteristics": "Caratteristiche",
        "comment": "Commento"
      },
      "label": {
        "glidingSnowActivity": "attività di slittamento",
        "snowHeight": "altezza neve",
        "zeroDegreeIsotherm": "Isoterma a 0°C",
        "looseSnowMoisture": "Umidità neve a debole coesione",
        "looseSnowGrainShape": "Tipologia grano neve a debole coesione",
        "slabGrainShape": "Tipologia grano lastrone",
        "slabThickness": "Spessore lastrone",
        "slabHandHardness": "Durezza mano lastrone",
        "slabHardnessProfile": "Durezza lastrone",
        "slabEnergyTransferPotential": "Potenzialità del lastrone di supportare la propagazione",
        "slabDistribution": "Distribuzione del lastrone",
        "weakLayerGrainShape": "Tipologia grani dello strato debole",
        "weakLayerGrainSize": "Dimensione grani dello strato debole",
        "weakLayerPersistent": "Persistenza dello strato debole",
        "weakLayerThickness": "Spessore dello strato debole",
        "weakLayerStrength": "Resistenza al taglio strato debole",
        "weakLayerWet": "Umidità dello strato debole",
        "weakLayerCrustAbove": "Crosta al di sopra dello strato debole",
        "weakLayerCrustBelow": "Crosta al di sotto dello strato debole",
        "weakLayerPosition": "Posizione dello strato debole nel manto nevoso",
        "weakLayerCreation": "Formazione di uno strato debole",
        "weakLayerDistribution": "Distribuzione dello strato debole",
        "dangerSpotRecognizability": "Riconoscibilità dei punti pericolosi",
        "remoteTriggering": "Distacco a distanza",
        "hasDaytimeDependency": "Dipendenza diurna",
        "dangerIncreaseWithElevation": "Pericolo aumenta con la quota",
        "runoutIntoGreen": "Valanghe arrivano fino ai prati",
        "highestDangerAspect": "Esposizione al pericolo più elevato",
        "dangerPeak": "Picco del pericolo",
        "slopeGradient": "Inclinazione del pendio",
        "naturalRelease": "Distacco spontaneo",
        "terrainTypes": "Tipo di terreno",
        "dangerSigns": "Segni d'allarme",
        "aspects": "Esposizione"
      },
      "editVariantDialog": {
        "save": "Salva",
        "cancel": "Cancella"
      },
      "deleteVariantDialog": {
        "message": "Vuoi cancellare questa variante?",
        "accept": "Sì",
        "reject": "No"
      },
      "noRegionDialog": {
        "message": "Almeno una micro-area deve appartenere la tua Provincia.",
        "accept": "Ok"
      },
      "discardDialog": {
        "message": "Vuoi scartare le modifiche?",
        "accept": "Sì",
        "reject": "No"
      },
      "saveErrorDialog": {
        "message": "Si è verificato un errore nel salvataggio delle origini di pericolo!",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Vuoi caricare tutte le varianti di origine del pericolo del giorno precedente?",
        "accept": "Sì",
        "reject": "No"
      }
    }
  },
  "avalancheType": {
    "title": "Tipi di valanghe",
    "label": {
      "slab": "LASTRONE",
      "loose": "A DEBOLE COESIONE",
      "glide": "SLITTAMENTO"
    },
    "tooltip": {
      "slab": "Valanga di neve a lastroni",
      "loose": "Valanga di neve a debole coesione",
      "glide": "Valanga di slittamento"
    }
  },
  "elevation": {
    "title": "Quota",
    "high": "al di sotto",
    "low": "al di sopra",
    "treeline": "Limite del bosco"
  },
  "bulletins": {
    "title": "Valanghe.report",
    "tooltip": {
      "edit": "Modifica bollettino",
      "read": "Leggi bollettino",
      "copy": "Copia bollettino valanghe",
      "paste": "Incolla bollettino valanghe",
      "cancel": "Annulla la copia",
      "update": "Modifica",
      "submit": "Invia",
      "submitUpdate": "Invia update",
      "publishChange": "Pubblica ora (senza messaggi)",
      "publishUpdate": "Pubblica ora",
      "caaml": "Mostra CAAML",
      "json": "Mostra JSON",
      "create": "Crea bollettino valanghe",
      "preview": "Anteprima del bollettino valanghe (PDF)",
      "check": "Controlla bollettino valanghe",
      "info": "Mostra lo stato pubblicazione",
      "publishAll": "Pubblica tutte le meteo-nivo zone",
      "mediaFile": "Carica file media"
    },
    "status": {
      "submittedInfo": "Verrà pubblicato alle 17:00",
      "resubmittedInfo": "Verrà pubblicato alle 08:00",
      "publishedInfo": "È stato pubblicato",
      "noPublicationInfo": "NON verrà pubblicato automaticamente",
      "loadingPreview": "Creare un'anteprima in PDF ...",
      "loading": "Carica bollettino ...",
      "loadMoreBulletins": "Carica {{count}} più bollettini",
      "publishing": "Pubblica bollettino ...",
      "submitting": "Invia bollettino ...",
      "saveError": "Non é stato possibile salvare il bollettino!",
      "loadBulletinsError": "Non é stato possibile caricare il bollettino!"
    },
    "create": {
      "tooltip": {
        "edit": "Modifica le micro-zone",
        "copy": "Copia meteo-nivo zona",
        "delete": "Elimina regione",
        "undo": "Annulla l'ultima modifica apportata alla meteo-nivo zona",
        "redo": "Ripeti l'ultima modifica nella meteo-nivo zona",
        "createRegion": "Crea meteo-nivo zona",
        "cancel": "Cancella",
        "acceptSuggestion": "Accetta meteo-nivo zone suggerite",
        "rejectSuggestion": "Scarta meteo-nivo zone suggerite",
        "saveChanges": "Salva modifiche",
        "discardChanges": "Scarta modifiche",
        "incompleteAvalancheProblem": "Problema valanghivo incompleto",
        "saveBulletin": "Salva bollettino valanghe",
        "discardBulletin": "Scarta modifiche del bollettino valanghe",
        "loadBulletin": "Copia il bollettino valanghe del giorno precedente",
        "uploadJsonBulletin": "Carica il bollettino valanghe in formato JSON",
        "downloadJsonBulletin": "Scarica il bollettino valanghe in formato JSON",
        "daytimeDependency": "Dipendenza diurna",
        "treeline": "Limite del bosco",
        "tendency": {
          "decreasing": "Pericolo valanghe in diminuazione",
          "steady": "Pericolo valanghe stabile",
          "increasing": "Pericolo valanghe in aumento",
          "danger": "Pericolo valanghe"
        },
        "textcat": {
          "edit": "Modifica",
          "copy": "Copia",
          "paste": "Incolla",
          "example": "Carica testo campione",
          "delete": "Elimina"
        },
        "moveUpAvalancheProblem": "Sposta problema valanghivo verso l'alto",
        "moveDownAvalancheProblem": "Sposta problema valanghivo verso il basso",
        "dangerRatingDirectionUp": "Verso l'alto",
        "dangerRatingDirectionDown": "Verso il basso"
      },
      "save": " Salva",
      "discard": " Scarta",
      "back": " Indietro",
      "createAggregatedRegion": " Nuova meteo-nivo zona",
      "pasteAggregatedRegion": "Incolla meteo-nivo zona",
      "pendingSuggestions": "Proposte",
      "rejectSuggestion": "Rifiuta",
      "acceptSuggestion": "Accetta",
      "cancelCopyBulletin": "Cancella",
      "createAvalancheProblem": "Nuovo problema valanghivo",
      "ownRegions": "Proprie meteo-nivo zone",
      "foreignRegions": "Altre meteo-nivo zone",
      "externalRegions": "Meteo-nivo zone esterne",
      "mapSelectRegion": "Seleziona micro-zone sulla mappa",
      "statusHeader": "Stato del bollettino",
      "suggestions": "Proposte",
      "noBulletinsForSelectedDay": "Nessun micro-zona definita per questa data.",
      "autosave": "Salvato automaticamente",
      "regionEditable": "Questa meteo-nivo zona può essere modificata.",
      "regionNotEditable": "Questa meteo-nivo zona non può essere modificata.",
      "regionLocked": "Questa meteo-nivo zona è bloccata.",
      "changeDangerRatingElevation": "Estendi il pericolo valanghe",
      "mapTop": "Mappa in alto",
      "mapLeft": "Mappa a lato",
      "showNotes": "Mostra gli appunti",
      "hideNotes": "Nascondi gli appunti",
      "incompleteAvalancheProblem": "Incompleto",
      "label": {
        "importantObservations": "Osservazioni importanti",
        "avalancheProblems": "Problema valanghivo",
        "aspects": "Esposizione",
        "highlights": "Allerta speciale",
        "avActivityHighlights": "Titolo situazione pericolo valanghe",
        "avActivityComment": "Descrizione pericolo valanghe",
        "snowpackStructureComment": "Descrizione struttura manto nevoso",
        "dangerPatterns": "Situazioni tipo",
        "tendencyComment": "Descrizione della tendenza",
        "notes": "Commenti"
      },
      "copyRegion": {
        "label": "Copia la meteo-nivo zona in un altro bollettino",
        "select": "Incolla in ...",
        "currentBulletin": "Questo bollettino"
      },
      "title": {
        "avalancheProblem": "Problemi valanghivi",
        "dangerDescription": "Descrizione pericolo valanghe",
        "forenoon": "Mattina",
        "afternoon": "Pomeriggio",
        "snowpackStructure": "Struttura manto nevoso",
        "tendency": "Tendenza",
        "showTranslations": " Mostra traduzione",
        "hideTranslations": " Nascondi traduzione"
      },
      "placeholder": {
        "notes": "Scrivi tuo commenti qui..."
      },
      "pmDialog": {
        "noIFrameSupport": "Il tuo browser non supporta IFrames!"
      },
      "discardDialog": {
        "message": "Vuoi scartare le modifiche?",
        "accept": "Sí",
        "reject": "No"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Vuoi cancellare la meteo-nivo zona?",
        "accept": "Sí",
        "reject": "No"
      },
      "noRegionDialog": {
        "message": "Almeno una micro-area deve appartenere la tua Provincia.",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Vuoi caricare il bollettino valanghe del giorno precedente?",
        "accept": "Sí",
        "reject": "No"
      },
      "loadAutoSaveDialog": {
        "message": "Vuoi caricare il bollettino valanghe salvato automaticamente?",
        "accept": "Sí",
        "reject": "No"
      },
      "saveErrorDialog": {
        "message": "Errore salvando il bollettino valanghe!",
        "accept": "Ok"
      },
      "changeErrorDialog": {
        "message": "Errore durante la modificazione del bollettino valanghe!",
        "accept": "Ok"
      },
      "loadingErrorDialog": {
        "message": "Errore durante il caricamento del bollettino valanghe!",
        "accept": "Ok"
      },
      "loadingJsonFileErrorDialog": {
        "message": "C'è stato un errore nel caricamento dei bollettini dal file JSON!",
        "accept": "Ok"
      },
      "avalancheProblemErrorDialog": {
        "message": "Manca il problema tipico valanghivo, l'esposizione, la stabilità del manto nevoso, la frequenza e/o la dimensione valanghe per almeno un problema valanghivo",
        "accept": "Ok"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "Per quale situazione vuoi caricare il testo?",
        "newSnow": "Neve fresca",
        "windSlab": "Lastroni da vento",
        "persistentWeakLayers": "Strati deboli persistenti",
        "wetSnow": "Neve bagnata",
        "glidingSnow": "Valanghe di slittamento",
        "favourableSituation": "Situazione favorevole",
        "cancel": "Cancella"
      },
      "removeDaytimeDependencyDialog": {
        "message": "Quali problemi valanghivi vuoi mantenere?",
        "cancel": "Cancella"
      },
      "strategicMindsetDialog": {
        "title": "Strategic Mindsets",
        "introduction": "Gli “Strategic Mindsets” sono intesi come espressione del nostro atteggiamento mentale nei confronti della situazione valanghiva e del suo sviluppo nella regione corrispondente. Non dovrebbero derivare direttamente dalla valutazione del pericolo, ma piuttosto essere compresi e utilizzati come un'impressione intuitiva della situazione generale. I “Strategic Mindsets” sono quindi principalmente uno strumento di comunicazione. Utilizzando i mindset e le definizioni associate, noi previsori possiamo scambiare in modo rapido ed efficiente informazioni sullo sviluppo della situazione di pericolo e trovare un linguaggio comune per la comunicazione.",
        "description": {
          "title": "Descrizione:",
          "text": {
            "assessment": "C'è una <b>grande incertezza</b> nella valutazione del pericolo a causa delle<b>informazioni insufficienti</b> osservati sul terreno.",
            "stepping_out": "<b>Tendenza alla diminuzione</b> della situazione di pericolo (anche all'interno di un grado di pericolo).",
            "status_quo": "Stessa situazione di pericolo.<b>Nessun cambiamento significativo</b> rispetto al giorno precedente.",
            "stepping_back": "<b>Tendenza all'aumento</b> della situazione di pericolo (anche all'interno di un livello di pericolo).",
            "entrenchment": "<b>Situazione di pericolo quasi costante e tesa.</b> Il pericolo principale deriva da (a) <b>uno strato debole persistente</b>(s) suscettibile al distacco.",
            "free_ride": "<b>Debole pericolo di valanghe</b> con un manto nevoso generalmente stabile. Sono possibili solo valanghe di dimensioni piccole nei terreni estremamente ripidi. ",
            "high_alert": "Situazione eccezionale con <b>pericolo di valanghe forte o molto forte</b>. Sono possibili valanghe spontanee di <b>dimensioni molto grandi o estreme </b>",
            "spring_diurnal": "Determinanti per la valutazione del rischio sono valanghe di neve umida nel corso della giornata che sono soggetti ad un <b>cicolo diurno chiaro</b>."
          }
        },
        "communication": {
          "title": "Comunicazione",
          "text": {
            "assessment": "La situazione valanghiva deve essere verificata a livello locale. Un comportamento prudente è opportuno.",
            "stepping_out": "Graduale diminuzione del pericolo. Tuttavia, occorre ancora prudenza e valutare localmente il miglioramento della situazione prima di addentrarsi in terreni più rischiosi. ",
            "status_quo": "La situazione di pericolo è invariata. Il riscontro degli osservatori aiuta a delineare meglio la situazione di pericolo. I terreni più favorevoli devono essere comunicati e messi in evidenza.",
            "stepping_back": "Comunicazione chiara dell'aumento del pericolo di valanghe, anche all'interno di un grado di pericolo. Informazioni sulle cause supportano la comprensibilità della comunicazione.",
            "entrenchment": "Sono necessarie cautela e moderazione. È difficile che la situazione cambi. Se possibile, fornire informazioni il più possibile precise sulla distribuzione e sulla suscettibilità al distacco. Maggiore comunicazione delle osservazioni che rendono evidente la situazione e inducono alla un comportamento prudente.",
            "free_ride": "Poco testo. Sottolineare la situazione generale favorevole e le zone di pericolo potenzialmente isolate.",
            "high_alert": "Comunicazione su tutti i canali. L'attenzione non è più rivolta agli sport invernali. Pericolo anche in aree solitamente non a rischio di valanghe.",
            "spring_diurnal": "Situazione favorevole al mattino, pericolo in aumento durante il giorno. La valutazione va fatta localmente osservando l'ammorbidimento della crosta da fusione e rigelo e la crescente umidificazione del manto nevoso."
          }
        },
        "examples": {
          "title": "Esempi:",
          "text": {
            "assessment": "Inizio stagione.",
            "stepping_out": "Un raffreddamento porta a una diminuzione del pericolo di valanghe di neve bagnata dopo una fase di tempo caldo e umido.",
            "status_quo": "Problema persistente di lastroni da vento con temperature fredde, neve a debole coesione e vento.",
            "stepping_back": "Nevicate abbondanti portano a un aumento della situazione di pericolo.",
            "entrenchment": "Problema critico di strati deboli persistenti con potenziale per valanghe pericolosamente grandi. La situazione si sta stabilizzando solo molto lentamente e richiede molta prudenza.",
            "free_ride": "Pericolo di valanghe debole, assenza di strati deboli persistenti, tutti i pendii sono già stati tracciati.",
            "high_alert": "Grado di pericolo 4 o superiore a causa di grandi quantità di neve fresca e/o strati deboli problematici e persistenti. ",
            "spring_diurnal": "Situazione primaverile con tempo secco e alta pressione e temperature moderate con un manto nevoso vecchio per lo più stabile."
          }
        },
        "strategicMindset": {
          "assessment": "Incertezza elevata",
          "stepping_out": "Miglioramento",
          "status_quo": "Status quo",
          "stepping_back": "Decelerazione",
          "entrenchment": "Persistente instabile",
          "free_ride": "Favorevole",
          "high_alert": "Massima allerta",
          "spring_diurnal": "Situazione primaverile"
        }
      },
      "decisionTree": {
        "filepath": "./assets/img/decision_tree-it.svg",
        "feedbackURL": "https://admin.avalanche.report/umfrage-entscheidungsbaum/en/",
        "decisionTree": "Albero decisionale",
        "feedback": "Feedback",
        "save": "Salva",
        "discard": "Scarta"
      }
    },
    "table": {
      "title": {
        "date": "Data",
        "status": {
          "AT-02": "Carinzia",
          "AT-03": "Bassa Austria",
          "AT-04": "Alta Austria",
          "AT-05": "Salisburghese",
          "AT-06": "Stiria",
          "AT-07": "Tirolo",
          "AT-08": "Vorarlberg",
          "CH": "Svizzera",
          "DE-BY": "Baviera",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentino",
          "IT-32-BZ": "Alto Adige",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "manca",
        "draft": "bozza",
        "submitted": "inviato",
        "published": "pubblicato",
        "updated": "aggiornato",
        "resubmitted": "versione aggiornata inviata",
        "republished": "versione aggiornata pubblicata"
      },
      "publishBulletinsDialog": {
        "message": "Vuoi pubblicare il bollettino valanghe?",
        "missingDangerRating": "Grado di pericolo non definito",
        "missingRegion": "  Meteo-nivo zone non valutate",
        "duplicateRegion": "Meteo-nivo zone valutate più volte",
        "missingAvActivityHighlights": "Manca il titolo situazione di pericolo",
        "missingAvActivityComment": "Manca la valutazione pericolo valanghe",
        "missingSnowpackStructureHighlights": "Manca il titolo della descrizione manto nevoso",
        "missingSnowpackStructureComment": "Manca la descrizione del manto nevoso",
        "pendingSuggestions": "Proposta non confermata",
        "incompleteTranslation": "Traduzione incompleta",
        "accept": "Sí",
        "reject": "No"
      },
      "publicationStatusDialog": {
        "headline": "Stato della pubblicazione",
        "publishedBy": "Pubblicato da: ",
        "publishedAt": "Pubblicato alle: ",
        "validFor": "Valido per: ",
        "title": {
          "prod": "Cose serie",
          "test": "Test system",
          "task": "Attività",
          "language": {
            "all": "TUTTO",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "email": {
          "label": "Email",
          "tooltip": "Inviare Email",
          "success": "{{prefix}}Email inviati con successo!",
          "error": "{{prefix}}Non è stato possibile inviare le Email!"
        },
        "telegram": {
          "label": "Messagio Telegram",
          "tooltip": "Invia messaggio via Telegram",
          "success": "{{prefix}}Messaggi via Telegram inviati con successo!",
          "error": "{{prefix}}Messaggi di Telegram non possono essere inviati!"
        },
        "push": {
          "label": "Notifica push",
          "tooltip": "Invia notifica push",
          "success": "{{prefix}}Notifiche push inviate con successo!",
          "error": "{{prefix}}Non è stato possibile inviare le notifiche push!"
        },
        "accept": "Ok"
      },
      "mediaFileDialog": {
        "headline": "File media",
        "important": "IMPORTANTE",
        "missingFile": "Nessun file selezionato!",
        "uploadError": "Upload fallito!",
        "accept": "Invia",
        "reject": "Cancella"
      },
      "publishBulletinsErrorDialog": {
        "message": "Errore pubblicando il bollettino valanghe!",
        "accept": "Ok"
      },
      "submitBulletinsDialog": {
        "message": "Vuoi inviare il bollettino valanghe?",
        "missingDangerRating": "Grado di pericolo non definito",
        "missingRegion": "Micro-zone non valutate",
        "missingAvActivityHighlights": "Manca titolo situazione di pericolo",
        "missingAvActivityComment": "Manca valutazione pericolo valanghe",
        "missingSnowpackStructureHighlights": "Manca il titolo della descrizione manto nevoso",
        "missingSnowpackStructureComment": "Manca la descrizione del manto nevoso",
        "pendingSuggestions": "Proposta non confermata",
        "accept": "Sí",
        "reject": "No"
      },
      "submitBulletinsErrorDialog": {
        "message": "Errore durante l'invio del bollettino valanghe!",
        "accept": "Ok"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Micro-aree valutate più volte! Non è possibile inviare il bollettino valanghe.",
        "accept": "Ok"
      },
      "checkBulletinsErrorDialog": {
        "message": "Errore verficando il bollettino valanghe!",
        "accept": "Ok"
      },
      "previewErrorDialog": {
        "message": "L'anteprima non può essere caricata!",
        "accept": "Ok"
      },
      "checkBulletinsDialog": {
        "message": "Controllare bollettino valanghe",
        "missingDangerRating": "Grado di pericolo non definito",
        "missingRegion": "Micro-aree non valutate",
        "missingAvActivityHighlights": "Manca titolo situazione di pericolo",
        "missingAvActivityComment": "Manca valutazione pericolo valanghe",
        "missingSnowpackStructureHighlights": "Manca il titolo della descrizione manto nevoso",
        "missingSnowpackStructureComment": "Manca la descrizione del manto nevoso",
        "pendingSuggestions": "Proposta non confermata",
        "incompleteTranslation": "Traduzione incompleta",
        "ok": "(tutto va bene)",
        "accept": "Ok"
      },
      "publishAllDialog": {
        "message": "Vuoi pubblicare tutte le meteo-nivo zone?",
        "accept": "Sí",
        "reject": "No"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Momento del training"
  },
  "admin": {
    "loadingStatistics": "Creazione CSV ...",
    "server-configuration": {
      "title": "dServer",
      "button": {
        "create": "Crea server",
        "save": "Salva"
      },
      "table": {
        "name": "Nome",
        "username": "Nome utente",
        "password": "Password",
        "publishAt5PM": "Pubblica alle 17:00",
        "publishAt8AM": "Publica alle 08:00",
        "pdfDirectory": "Cartella per PDF",
        "htmlDirectory": "Cartella per HTML",
        "serverImagesUrl": "Path alle immagini sul server",
        "mapsPath": "Path alle mappe",
        "mediaPath": "Path ai file media",
        "mapProductionUrl": "URL per la produzione di mappe",
        "apiUrl": "API URL"
      },
      "success": "Configurazione salvata con successo",
      "error": "Non é stato possibile salvare le configurazione"
    },
    "region-configuration": {
      "title": "Regione",
      "button": {
        "create": "Crea regione",
        "save": "Salva"
      },
      "table": {
        "general": {
          "title": "In generale",
          "id": "ID",
          "microRegions": "Numero micro-zone",
          "subRegions": "Sottoregioni",
          "superRegions": "Sovraregioni",
          "neighborRegions": "Regioni confinanti"
        },
        "publication": {
          "title": "Pubblicazione",
          "publishBulletins": "Publica bollettino valanghe",
          "publishBlogs": "Publica i post blog",
          "createCaamlV5": "Crea CAAML v5",
          "createCaamlV6": "Crea CAAML v6",
          "createJson": "Crea JSON",
          "createMaps": "Crea mappe",
          "createPdf": "Crea PDF",
          "sendEmails": "Invia email",
          "createSimpleHtml": "Crea HTML semplici",
          "sendTelegramMessages": "Invia messaggi via Telegram",
          "sendPushNotifications": "Invia notifiche push",
          "pdfColor": "PDF a colori",
          "emailColor": "Email a colori",
          "pdfMapYAmPm": "Y per mappa PDF (am/pm)",
          "pdfMapYFd": "Y per mappa PDF (fd)",
          "pdfMapWidthAmPm": "Larghezza mappa per PDF (am/pm)",
          "pdfMapWidthFd": "Larghezza mappa per PDF (fd)",
          "pdfMapHeight": "Altezza mappa per PDF",
          "pdfFooterLogo": "Logo per piè di pagina PDF",
          "pdfFooterLogoColorPath": "Logo per piè di pagina PDF (a colori)",
          "pdfFooterLogoBwPath": "Logo per piè di pagina PDF (bianco/nero)",
          "mapXmax": "Mappa X massimo",
          "mapXmin": "Mappa X minimo",
          "mapYmax": "Mappa Y massimo",
          "mapYmin": "Mappa Y minimo",
          "simpleHtmlTemplateName": "Template HTML semplice",
          "geoDataDirectory": "Cartella geodati",
          "mapLogoColorPath": "Logo per mappa (a colori)",
          "mapLogoBwPath": "Logo per mappa (bianco/nero)",
          "mapLogoPosition": "Posizione del logo nella mappa",
          "mapCenterLat": "Posizione centrale della mappa (lat)",
          "mapCenterLng": "Posizione centrale della mappa (long)",
          "imageColorbarColorPath": "Barra colori (a colori)",
          "imageColorbarBwPath": "Barra colori (bianco/nero)"
        },
        "components": {
          "title": "Componenti",
          "enableDangerSources": "Attiva fonti di pericolo",
          "enableObservations": "Attiva osservazioni",
          "enableModelling": "Attiva modellazione",
          "enableWeatherbox": "Attiva box meteo"
        },
        "configuration": {
          "title": "Configurazione",
          "showMatrix": "Mostra matrice",
          "enableMediaFile": "Attiva file media",
          "enableStrategicMindset": "Attiva mindset strategico",
          "enableStressLevel": "Attiva livello di stress",
          "enableAvalancheProblemCornices": "Attivare problema tipico valanghivo CORNICI",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Attiva problema tipico valanghivo NESSUN PROBLEMA VALANGHIVO EVIDENTE"
        }
      },
      "success": "Configurazione salvata con successo",
      "error": "Non é stato possibile salvare la configurazione"
    },
    "users": {
      "title": "Utenti",
      "button": {
        "create": "Crea utente"
      },
      "tooltip": {
        "edit": "Modifica utente",
        "changePassword": "Cambia password",
        "delete": "Cancella utente"
      },
      "table": {
        "name": "Nome",
        "email": "Email",
        "organization": "Organizzazione",
        "regions": "Meteo-nivo zone",
        "roles": "Ruoli"
      },
      "deleteUserModalDialog": {
        "message": "Voi cancellare questo utente?",
        "accept": "Sì",
        "reject": "No"
      },
      "deleteUser": {
        "success": "Utente cancellato",
        "error": "L'utente non può essere cancellato"
      },
      "createUser": {
        "success": "Utente creato",
        "error": "L'utente non può essere creato"
      },
      "updateUser": {
        "success": "Utente aggiornato",
        "error": "L'utente non può essere aggiornato"
      }
    },
    "createUser": {
      "table": {
        "image": "Immagine",
        "name": "Nome",
        "email": "Email",
        "organization": "Organizazzione",
        "password": "Password",
        "password2": "Ripeti la password",
        "roles": "Ruoli",
        "regions": "Regioni"
      },
      "error": {
        "name": {
          "required": "Inserire un nome"
        },
        "email": {
          "required": "Inserire un indirizzo email",
          "invalid": "L'indirizzo Email non è valido"
        },
        "password": {
          "required": "Inserisci una password",
          "length": "La password deve essere di almeno 8 caratteri",
          "mismatch": "Le password non corrispondono"
        }
      },
      "button": {
        "create": "Crea utente",
        "update": "Aggiorna utente",
        "cancel": "Cancella"
      }
    },
    "observations": {
      "table": "Tabella",
      "map": "Mappa",
      "gallery": "Galleria",
      "filters": "Filtri",
      "title": "Osservazioni",
      "newObservation": "Nuova osservazione",
      "exportObservations": "Esporta osservazioni",
      "toggleFilterBar": "Visualizza/nascondi barra dei filtri",
      "button": {
        "submit": "Scarica"
      },
      "label": {
        "dateRange": "Periodo",
        "observer": "Osservatore"
      },
      "selectRegion": {
        "placeholder": "Meteo-nivo zona",
        "selectionLabel": "{0} meteo-nivo zona/e selezionata/e"
      },
      "selectSources": {
        "placeholder": "origine dati",
        "selectionLabel": "{0} origine/i selezionato/i"
      },
      "charts": {
        "charts": "Diagrammi",
        "nan": "Senza specificazione",
        "classify": "Classifica",
        "label": "Denominazione",
        "invert": "Invert",
        "reset": "Reset",
        "aspect": {
          "caption": "Esposizione"
        },
        "elevation": {
          "caption": "Quota"
        },
        "stability": {
          "caption": "Stabilità"
        },
        "observationType": {
          "caption": "Tipo di osservazione"
        },
        "importantObservation": {
          "caption": "Importante"
        },
        "avalancheProblem": {
          "caption": "Problema valanghivo"
        },
        "dangerPattern": {
          "caption": "Situazione tipo"
        },
        "days": {
          "caption": "Giorno"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Lingua",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Invia email, Telegram e Push",
        "success": "{{prefix}}Email, Telegram e Push inviati con successo!",
        "error": "{{prefix}}Non è stato possibile inviare email, Telegram e Push!"
      },
      "email": {
        "tooltip": "Invia email",
        "success": "{{prefix}}Email inviato con successo!",
        "error": "{{prefix}}Non è stato possibile inviare email!"
      },
      "telegram": {
        "tooltip": "Pubblicazione via canale Telegram",
        "success": "{{prefix}}Telegram inviato con successo",
        "error": "{{prefix}}Non è stato possibile inviare i messaggi Telegram!"
      },
      "push": {
        "tooltip": "Invia tramite notifiche push",
        "success": "{{prefix}}Push inviato con successo",
        "error": "{{prefix}}Non è stato possibile inviare notifiche Push"
      }
    }
  },
  "settings": {
    "title": "Impostazioni",
    "changePassword": {
      "title": "Cambia password",
      "oldPassword": {
        "label": "Password attuale",
        "placeholder": "Password attuale"
      },
      "newPassword1": {
        "label": "Nuova password",
        "placeholder": "Nuova password"
      },
      "newPassword2": {
        "label": "Nuova password (ripetere)",
        "placeholder": "Nuova password"
      },
      "submit": "Cambia password",
      "passwordChanged": "Password modificata con sucesso",
      "passwordChangeError": "Non é stato possibile cambiare la password",
      "passwordIncorrect": "Password attuale non corretta",
      "error": {
        "oldPassword": {
          "required": "Inserire password attuale"
        },
        "newPassword": {
          "required": "Inserire nuova password",
          "mismatch": "Le password non corrispondono"
        }
      }
    },
    "display": {
      "title": "Mostra",
      "table": {
        "showCaaml": "Mostra CAAML",
        "showJson": "Mostra JSON"
      }
    }
  },
  "statistics": {
    "title": "Statistiche",
    "button": {
      "submit": "Scarica"
    },
    "label": {
      "dateRange": "Periodo",
      "extended": "Esteso",
      "duplicates": "Doppioni"
    }
  },

  "dangerRating": {
    "noRating": "senza valutazione",
    "low": "debole",
    "moderate": "moderato",
    "considerable": "marcato",
    "high": "forte",
    "veryHigh": "molto forte"
  },
  "avalancheProblem": {
    "newSnow": "Neve fresca",
    "new_snow": "Neve fresca",
    "windSlab": "Lastroni da vento",
    "wind_slab": "Lastroni da vento",
    "persistentWeakLayers": "Strati deboli persistenti",
    "persistent_weak_layers": "Strati deboli persistenti",
    "wetSnow": "Neve bagnata",
    "wet_snow": "Neve bagnata",
    "glidingSnow": "Valanghe di slittamento",
    "gliding_snow": "Valanghe di slittamento",
    "cornices": "Cornici",
    "noDistinctAvalancheProblem": "Nessun problema valanghivo evidente",
    "no_distinct_avalanche_problem": "Nessun problema valanghivo evidente",
    "favourableSituation": "Situazione favorevole",
    "favourable_situation": "Situazione favorevole"
  },
  "dangerPattern": {
    "dp1": "st.1: strato debole persistente basale",
    "dp2": "st.2: neve di slittamento",
    "dp3": "st.3: pioggia su neve",
    "dp4": "st.4: freddo su caldo / caldo su freddo",
    "dp5": "st.5: neve dopo un lungo periodo di freddo",
    "dp6": "st.6: neve a debole coesione e vento",
    "dp7": "st.7: passaggio da poca a molta neve",
    "dp8": "st.8: brina di superficie sepolta",
    "dp9": "st.9: neve pallottolare sepolta",
    "dp10": "st.10: situazione primaverile"
  },
  "strategicMindset": {
    "title": "Mondset strategico",
    "assessment": "Elevata incertezza",
    "stepping_out": "Miglioramento",
    "status_quo": "Status quo",
    "stepping_back": "Decelerazione",
    "entrenchment": "Persistente instabile",
    "free_ride": "Favorevole",
    "high_alert": "Massima allerta",
    "spring_diurnal": "Situazione primaverile"
  },
  "observationType": {
    "TimeSeries": "Serie cronologica",
    "Incident": "Incidente",
    "Profile": "Profilo stratigrafico",
    "Closure": "Chiusura",
    "Blasting": "Brillamento",
    "Avalanche": "valanga",
    "Evaluation": "Valutazione",
    "SimpleObservation": "Osservazione semplice",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "ForBlog": "Per il blog",
    "SnowLine": "Limite delle nevicate",
    "SurfaceHoar": "Brina di superficie",
    "Graupel": "Neve pallottolare",
    "StabilityTest": "Test di stabilità",
    "IceFormation": "Formazione di ghiaccio",
    "VeryLightNewSnow": "Neve fresca leggerissima"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SO",
    "W": "O",
    "NW": "NO"
  },
  "role": {
    "SUPERADMIN": "Super Amministratore",
    "ADMIN": "Amministratore",
    "FORECASTER": "Previsore",
    "FOREMAN": "Caposquadra",
    "OBSERVER": "Osservatore"
  },
  "tendency": {
    "decreasing": " Pericolo valanghe in diminuizione",
    "steady": " Pericolo valanghe stabile",
    "increasing": " Pericolo valanghe in aumento"
  },
  "snowpackStability": {
    "unknown": "sconosciuto",
    "good": "buona",
    "fair": "discreta",
    "poor": "scarsa",
    "very_poor": "molto scarsa"
  },
  "frequency": {
    "none": "pochissimi",
    "few": "pochi",
    "some": "alcuni",
    "many": "molti"
  },
  "avalancheSize": {
    "small": "piccola",
    "medium": "media",
    "large": "grande",
    "very_large": "molto grande",
    "extreme": "estremamente grande"
  },
  "complexity": {
    "easy": "Facile",
    "challenging": "Impegnativo",
    "complex": "Complesso"
  },
  "glidingSnowActivity": {
    "low": "debole",
    "medium": "media",
    "high": "forte"
  },
  "wetness": {
    "dry": "asciutta",
    "moist": "umida",
    "wet": "bagnata"
  },
  "characteristic": {
    "low": "debole",
    "medium": "media",
    "high": "forte",
    "very_high": "molto forte"
  },
  "thickness": {
    "thin": "sottile",
    "thick": "spesso"
  },
  "snowpackPosition": {
    "upper": "superiore",
    "middle": "centrale",
    "lower": "inferiore",
    "ground": "suolo"
  },
  "creationProcess": {
    "radiation_recrystallization": "radiazione",
    "diurnal_recrystallization": "diurno",
    "melt_layer_recrystallization": "crosta da fusione"
  },
  "recognizability": {
    "very_easy": "molto facile",
    "easy": "facile",
    "hard": "duro",
    "very_hard": "molto duro"
  },
  "distribution": {
    "isolated": "isolato",
    "specific": "determinato",
    "widespread": "diffuso"
  },
  "probability": {
    "likely": "probabile",
    "possible": "possibile",
    "unlikely": "poco probabile"
  },
  "detailedDaytime": {
    "evening": "sera",
    "first_night_half": "prima metà della notte",
    "second_night_half": "seconda metà della notte",
    "morning": "primo mattino",
    "forenoon": "mattina",
    "afternoon": "pomeriggio"
  },
  "slopeGradient": {
    "moderately_steep": "moderatamente ripido",
    "steep": "ripido",
    "very_steep": "molto ripido",
    "extremely_steep": "estremamente ripido"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "al di sotto più morbido",
    "steady": "uniforme",
    "increasing": "al di sopra più morbido"
  },
  "terrainType": {
    "gullies": "canaloni",
    "bowls": "conche",
    "pass_areas": "zone ai passi",
    "ridge_lines": "alle creste"
  },
  "dangerSign": {
    "shooting_cracks": "crepe",
    "whumpfing": "rumori \"whumpf\""
  },
  "changeRegionDialog": {
    "message": "Vuoi cambiare la meteo-nivo zona?",
    "accept": "Sì",
    "reject": "No"
  },
  "zamg": {
    "table": "Tabella",
    "map": "Mappa",
    "region": "Meteo-nivo zona",
    "newSnow": "Neve fresca"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "Neve bagnata: contenuto in acqua",
      "wet_snow_instability": "Neve bagnata: Instabilità neve bagnata",
      "Sk38_stratigraphy": "strati deboli persistenti: Indice stabilità SK38",
      "stratigraphy": "strati deboli persistenti: stratigrafia"
    },
    "aspect": {
      "flat": "piano",
      "north": "Versante nord",
      "south": "Versante sud"
    },
    "meteo": {
      "new_snow_plot_3day": "Neve fresca (3gg)",
      "new_snow_plot_7day": "Neve fresca (7g)",
      "new_snow_plot_1month": "Neve fresca (31g)",
      "new_snow_plot_season": "Neve fresca (stagione)",
      "new_snow_plot_forecast": "Neve fresca (previsione)",
      "wet_snow_plot_3day": "Neve bagnata (3g)",
      "wet_snow_plot_7day": "Neve bagnata (7g)",
      "wet_snow_plot_1month": "Neve bagnata (31g)",
      "wet_snow_plot_season": "Neve bagnata (stagione)",
      "wet_snow_plot_forecast": "Neve bagnata (previsione)",
      "HS_table_24h": "Tabella altezza neve (1g)",
      "HS_table_72h": "Tabella altezza neve (3g)",
      "HS_table_season": "Tabella altezza neve (stagione)",
      "HS_table_forecast": "Tabella altezza neve (previsione)",
      "TA_table_24h": "Tabella temperatura (1g)",
      "TA_table_72h": "Tabella temperatura (3ore)",
      "TA_table_season": "Tabella temperatura (stagione)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Neve fresca"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Neve pallottolare"
    },
    "DF": {
      "code": "DF",
      "class": "Cristalli feltrati"
    },
    "RG": {
      "code": "RG",
      "class": "Grani arrotondati"
    },
    "FC": {
      "code": "FC",
      "class": "Cristalli sfaccettati"
    },
    "DH": {
      "code": "DH",
      "class": "Brina di profondità"
    },
    "SH": {
      "code": "SH",
      "class": "Brina di superficie"
    },
    "MF": {
      "code": "MF",
      "class": "Forme da fusione"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Crosta da fusione e rigelo"
    },
    "IF": {
      "code": "IF",
      "class": "Formazioni di ghiaccio"
    }
  },
  "button": {
    "yes": "si",
    "no": "no"
  },
  "observations": {
    "allowEdit": "Modifica osservazione esterna",
    "allowEditTooltip": "Questo impedisce la sovrascrittura di {{ value }}",
    "editIconTooltip": "Osservazione modificata - non viene aggiornato {{ value }}",
    "aspect": "Esposizione",
    "authorName": "Nome dell'autore",
    "avalancheLength": "Lunghezza della valanga",
    "avalancheProblem": "Problema valanghivo",
    "avalancheSize": "Dimensione della valanga",
    "avalancheType": "Tipologia della valanga",
    "avalancheWidth": "Larghezza della valanga",
    "search": "Cerca",
    "count": "# osservazioni",
    "layers": {
      "observers": "Osservatori",
      "weatherStations": "Stazioni nivometeorologiche automatiche",
      "webcams": "Webcam",
      "observations": "Osservazioni"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Radiazione globale",
        "newSnow": "Neve fresca",
        "snowLine": "Limite delle nevicate",
        "snowHeight": "altezza neve",
        "snowDifference24h": "Differenza altezza neve 24h",
        "snowDifference48h": "Differenza altezza neve 48h",
        "snowDifference72h": "Differenza altezza neve 72h",
        "airTemperature": "Temperatura dell'aria",
        "airTemperatureMax": "Temperatura dell'aria max.",
        "airTemperatureMin": "Temperatura dell'aria min.",
        "surfaceTemperature": "Temperatura superficiale",
        "surfaceHoar": "Formazione potenziale di brina di superficie",
        "surfaceHoarCalc": "Formazione potenziale di brina di superficie (Lehning et. al.)",
        "dewPoint": "Temperatura di rugiada",
        "relativeHumidity": "Umidità relativa",
        "windDirection": "Direzione del vento",
        "windSpeed": "Velocità del vento",
        "windGust": "Raffica"
      }
    },
    "button": {
      "add": "Aggiungi osservazione",
      "save": "Salva l'osservazione",
      "delete": "Cancella l'osservazione",
      "deleteConfirm": "Cancella l'osservazione?",
      "discard": "Scarta",
      "copyDate": "Copia data evento"
    },
    "comment": "Commento",
    "content": "Contenuto",
    "dangerRating": "Grado pericolo valanghe",
    "elevation": "Quota",
    "elevationRange": "Fascia altitudinale",
    "elevationLowerBound": "Limite inferiore quota",
    "elevationUpperBound": "Limite superiore quota",
    "eventDate": "Data dell'evento",
    "eventType": "Tipologia dell'evento",
    "eventTypes": {
      "IMPORTANT": "Evento importante",
      "NEIGHBOR_REGION": "Regione confinante",
      "NORMAL": "Evento normale",
      "PERSON_DEAD": "Evento con persone coinvolte (vittime)",
      "PERSON_INJURED": "Evento con persone coinvolte (feriti)",
      "PERSON_NO": "Evento senza coinvolgimento persone",
      "PERSON_UNINJURED": "Evento che coinvolge persone (non ferite)",
      "PERSON_UNKNOWN": "Evento (sconosciuto)",
      "TRAFFIC": "Traffico"
    },
    "externalURL": "URL esterna (es. al database delle foto)",
    "fractureDepth": "Spessore del lastrone",
    "hasCoordinates": "Contiene coordinate",
    "incline": "Pendenza",
    "latitude": "Latitudine",
    "locationName": "Località",
    "longitude": "Longitudine",
    "observationType": "Tipo di osservazione",
    "personInvolvement": "Coinvolgimento di persone",
    "reportDate": "Data del report",
    "showTable": "Tabella",
    "withoutCoordinates": "Osservazioni senza coordinate:",
    "sidebar": {
      "filter": {
        "title": "Filtro"
      },
      "legend": {
        "title": "Leggenda"
      },
      "sources": {
        "title": "Fonti dei dati"
      },
      "views": {
        "title": "Views"
      }
    }
  },
  "personInvolvement": {
    "Dead": "Evento con persone coinvolte (vittime)",
    "Injured": "Evento con persone coinvolte (feriti)",
    "No": "Evento senza coinvolgimento persone",
    "Uninjured": "Evento con coinvolgimento di persone (non ferite)",
    "Unknown": "Evento (sconosciuto)"
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Giorno",
    "time": "Ora",
    "back": " Indietro",
    "parameters": {
      "DUST": "polvere sahariana",
      "N": "nuvolosità totale in /8",
      "Nh": "nuvolosità alta in /8",
      "Nm": "nuvolosità media in /8",
      "Nl": "nuvolosità bassa in /8",
      "N-CU": "copertura con nubi cumuliforme in /8",
      "AUSL.T": "temperatura trigger",
      "SHW-IX": "Indice Showalter",
      "WX -CUF": "tempo significativo: NIL (nulla), RASH (pioggia), TS (temporale)",
      "RR": "precipitazioni totali [mm]",
      "RR-str": "precipitazioni stratiformi",
      "CONV": "precipitazioni convettive [mm]",
      "SN": "precipitazioni sotto forma di neve [mm]",
      "SN--RA": "limite delle nevicate [m]",
      "QAO ": "{{value}} hPa eq. vento medio, direzione e forza del vento, [°] e [m/s]",
      "QAN": "vento medio al suolo, direzione e forza, [°] e [m/s]",
      "QANmax": "vento max. (raffiche) al suolo [m/s]",
      "T ": "{{value}} hPa eq. temperatura [°C]",
      "T m": "temperatura a {{value}}m [°C]",
      "T 2m": "temperatura a 2m [°C]",
      "Tk 2m": "temperatura a 2m [°K]",
      "T GND": "temperatura al suolo",
      "Max-ADI": "temperatura massima di miscelazione [°C]",
      "Min Max": "temperatura min./max. nelle ultime 24h",
      "FROST": "limite degli zero gradi [m]",
      "W cm": "500 hPa eq. vento verticale [cm/s]",
      "W --": "700 hPa eq. vento verticale [cm/s]",
      "W s": "{{value}} hPa eq. vento verticale [cm/s]",
      "H ": "{{value}} hPa quota geopotenziale [m]",
      "RF ": "{{value}} hPa eq. umidità relativa [%]"
    }
  }
}
